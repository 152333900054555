import axios from 'axios'

const BASEURL = process.env.NODE_ENV === 'production' ? '/api' : '/api'
const service = axios.create({
    baseURL: BASEURL,
    //baseURL: 'http://127.0.0.1:3000/',
    timeout: 3000,
    withCredentials: true,
})



//请求拦截器
service.interceptors.request.use(config => {
    console.log(config);
    // config.headers.Authorization = window.sessionStorage.getItem('token');
    // 在最后必须 return config
    return config;
  })

export default service