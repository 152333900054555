import request from '@/utils/request'

export function userLogin(data) {
    return request({
        url: '/user/login',
        method: 'post',
        data
    })
}

export function userInfo(data) {
    return request({
        url: '/user/info',
        method: 'post',
        data
    })
}


