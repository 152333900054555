<template>
  <div>
      <el-button type="primary" @click="verify"></el-button>
  </div>
</template>

<script>
import { userInfo } from "@/api/user";

export default {
 data(){
   return {
     query:{
       name:'1'
     }
   }
 },
 methods:{
    async verify(){
      const { data: res } = await userInfo(this.query);
      if (res.status !== 200) {
        
      }
      console.log('success')
    },
 }
}
</script>

<style>

</style>