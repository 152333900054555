<template>
  <div class="login_container">
    <div class="login_title"><h3>个人博客系统</h3></div>
    <div class="login_box">
      <!-- 头像区域 -->
      <div class="avatar_box">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0px"
        class="login_form"
      >
        <!-- 用户名 -->
        <el-form-item prop="userName">
          <el-input
            v-model="loginForm.userName"
            prefix-icon="el-icon-user"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="passWord">
          <el-input
            v-model="loginForm.passWord"
            prefix-icon="el-icon-lock"
            type="password"
          ></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login">登录</el-button>
          <el-button type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="footer">
      备案号：<a href="https://beian.miit.gov.cn">粤ICP备2020118937号</a>
    </div>
  </div>
</template>

<script>
import { userLogin } from "@/api/user";

export default {
  data() {
    return {
      //登录表单数据
      loginForm: {
        userName: "",
        passWord: "",
      },
      //登录表单验证规则
      loginFormRules: {
        userName: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "长度在 8 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //点击登录调用登录接口并跳转
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;
        //let loginData = this.$qs.stringify(this.loginForm);

        const { data: res } = await userLogin(this.loginForm);
        if (res.status !== 1) {
          return this.$message.error(res.msg);
        }
        this.$message.success("登录成功！");
        this.$router.push("/home");
      });
    },
    // 点击重置按钮将数据恢复至默认数据
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.login_container {
  background-color: #cccc99;
  //background: url("../assets/img/back.jpg") no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}

.login_title {
  text-align: center;
}

.login_title h3 {
  font-size: 36px;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -50%);
}

.footer {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, -50%);
}
</style>
